<template>
    <div class="plugCode">
        <div class="group-table padd20">
            <div class="duoshao">
                <div class="title">插件激活码列表</div>
                <div class="jihmsc">
                    <div class="title">生成个数：</div>
                    <el-input v-model="num" placeholder="请输入内容" style="margin-left:20px"></el-input>
                    <el-button type="primary" @click="oncreateRegistrationCode()">生成激活码</el-button>
                </div>
                <div class="lvtotal">
                     总条数：{{lvtotal}} 条
                </div>
            </div>
             <el-table ref="filterTable" :data="CodeList" style="width: 100%">
                <el-table-column prop="id" label="id"></el-table-column>
                <el-table-column prop="code" label="激活码">
                    <template slot-scope="scope">
                        <span style="color:#f00">{{scope.row.code}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="id" label="状态">
                    <template slot-scope="scope">
                        <span :style="`color:${scope.row.status == 1 ? '' : '#67c23a'}`">{{scope.row.status == 1 ? '未激活' : '已激活'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="created_at" label="生成时间"></el-table-column>
                <el-table-column prop="expriy_date" label="到期时间"></el-table-column>
                <el-table-column prop="id" label="操作">
                    <template slot-scope="scope">
                        <el-button type="danger" @click="ondelRegistrationCode(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
             </el-table>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            CodeList:[],
            lvtotal:'',
            codeDialog:false,
            num:'',
        };
    },
    
    mounted() {
        this.ongetRegistrationCodeList()
    },

    methods: {
        ongetRegistrationCodeList(){
            this.$service.get(this.$api.getRegistrationCodeList,{}, (res)=> {
                if(res.code == '200'){
                    this.CodeList = res.data.data
                    this.lvtotal = res.data.total
                }
            })
        },
        //删除
        ondelRegistrationCode(row){
            var param = {
                id:row.id
            }
            this.$service.post(this.$api.delRegistrationCode,param, (res)=> {
                if(res.code == '200'){
                    this.$message({message: '删除成功',type: 'success'});
                    this.ongetRegistrationCodeList()
                }
            })
        },
        //生成激活码
        oncreateRegistrationCode(){
            if(this.num == ''){
                this.$message({message: '请输入生成个数',type: 'warning'});
                return
            }
            var param = {
                num:this.num
            }
            this.$service.post(this.$api.createRegistrationCode,param, (res)=> {
                if(res.code == '200'){
                    this.$message({message: '生成成功',type: 'success'});
                    this.ongetRegistrationCodeList()
                }
            })
        }
    },

    

    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
.plugCode{
    .jihmsc{
        display: flex;
        width: 700px;
        align-items: center;
        margin-left: 40px;
        .el-input{
            width: 200px;
        }
    }
}
</style>
<style lang="scss">
</style>